// @flow

import React, { Component } from "react";
import Retool from "react-retool";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { withAcl } from "../Acl";
import { load } from "../../redux/modules/embed-retool";

type PropsType = {
    embedRetool: *,
    getEmbedUri: () => void,
};

class EmbedRetool extends Component<PropsType> {
    componentDidMount() {
        this.props.getEmbedUri();
    }

    render() {
        const {
            embedRetool: { data, error, loading },
        } = this.props;

        if (loading || data === null) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error</div>;
        }

        return <Retool url={data.url} />;
    }
}

export default withAcl(
    withRouter(
        connect(
            ({ embedRetool }) => ({ embedRetool }),
            (dispatch: *) =>
                bindActionCreators({ getEmbedUri: load }, dispatch),
        )(EmbedRetool),
    ),
);
