//@flow

type FieldErrorInput = {
    field: string,
    message: string,
};

export type FieldErrors = {
    [key: string]: string,
};

export default class FormError extends Error {
    messages: FieldErrors;

    constructor(messages: FieldErrorInput[]) {
        const transformed: FieldErrors = messages.reduce(
            (acumulator, current) => {
                acumulator[current.field] = current.message;
                return acumulator;
            },
            {},
        );

        super(`FormError: ${JSON.stringify(transformed, null, 4)}`);
        this.messages = transformed;
    }
}
