// @flow

import createApiModule from "../create-api-module";

const { reducer, actions } = createApiModule(
    "embedRetool",
    () => "ays-user-management/retool",
    {
        method: "POST",
    },
);

const load = () => (dispatch: *) => {
    return dispatch(actions.load({ data: { appName: "admin" } }, true));
};
const refetch = actions.refetch;
const clear = actions.clear;
const ERROR = "Could not fetch embed retool uri";

export { reducer as default, load, refetch, clear, ERROR };
